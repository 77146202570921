<template>
  <div class="wrapper-default-page profile d-flex justify-content-center align-items-center">
    <div class="d-flex gap-3 mx-auto flex-wrap text-center justify-content-center">
      <router-link v-if="$checkPermissions('scope.administration_dashboard')" :to="{ name: 'admin' }" class="btn btn-outline-danger">
        {{ $t('table.admin') }}
      </router-link>
      <router-link v-if="$checkPermissions('scope.broadcasting_grid')" :to="{ name: 'broadcast' }" class="btn btn-outline-danger">
        {{ $t('sideBar.broadcast') }}
      </router-link>
      <router-link v-if="$checkPermissions('broadcasting.channel_one_day')" :to="{ name: 'broadcast-one-day' }" class="btn btn-outline-danger">
        {{ $t('table.versionControl') }}
      </router-link>
      <router-link v-if="$checkPermissions('scope.marketing_grid')" :to="{ name: 'marketingGrid' }" class="btn btn-outline-danger">
        {{ $t('table.marketing') }}
      </router-link>
      <router-link v-if="$checkPermissions('grp_fact.bind', 'grp_fact.unbind')" :to="{ name: 'matchingFactImport' }" class="btn btn-outline-danger">
        {{ $t('table.matchingImport') }}
      </router-link>
      <router-link v-if="$checkPermissions('scope.project_order_mediaplan')" :to="{ name: 'projects' }" class="btn btn-outline-danger">
        {{ $t('sideBar.projects') }}
      </router-link>
      <router-link v-if="$checkPermissions('scope.booking_grid')" :to="{ name: 'booking' }" class="btn btn-outline-danger">
        {{ $t('table.booking') }}
      </router-link>
      <router-link v-if="$checkPermissions('booking.channel_one_day')" :to="{ name: 'booking-one-day' }" class="btn btn-outline-danger">
        {{ $t('broadcastSideBar.oneDay') }}
      </router-link>
      <router-link v-if="$checkPermissions('scope.mediaplan_automatic_list')" :to="{ name: 'automaticBooking' }" class="btn btn-outline-danger">
        {{ $t('broadcastSideBar.automatControl') }}
      </router-link>
      <router-link v-if="$checkPermissions('scope.commercial_list')" to="/commercials-list" class="btn btn-outline-danger">
        {{ $t('sideBar.commercials') }}
      </router-link>
      <router-link v-if="$checkPermissions('scope.reports')" :to="{ name: 'reports' }" target="_blank" class="btn btn-outline-danger">
        {{ $t('broadcastSideBar.reports') }}
      </router-link>
      <router-link v-if="RBEnabled && $checkPermissions('retro_bonus_discount.manage')" :to="{ name: 'retroBonusTable' }" class="btn btn-outline-danger">
        {{ $t('RBDiscounts.retroBonusDiscounts') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Profile',
  data() {
    return { RBEnabled: process.env.VUE_APP_ENABLE_RETROBONUS == 'TRUE' };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      user: 'getUser',
      isChief: 'getIsCurrentUserChief',
      isChiefTVNetwork: 'getIsCurrentUserChiefTVNetwork',
      isChiefChannel: 'getIsCurrentUserChiefChannel',
      isChiefSaleshouse: 'getIsCurrentUserChiefSaleshouse',
    }),
  },

  created() {
    document.title = this.$i18n.t('sideBar.profile') + ' – OpenMediaLogic';
    if (this.isChiefTVNetwork) this.$router.push({ name: 'reports', query: { report_type: 12 } });
    if (this.isChief || this.isChiefChannel || this.isChiefSaleshouse || this.isChiefSubchannel) this.$router.push({ name: 'reports' }); // no need in profile for Chief, redirect to Reports page
  },
};
</script>

<style lang="sass">
@media (max-width: 1400px)
  .wrapper-default-page.profile a.btn
    font-size: min(14px, 1rem)
</style>
